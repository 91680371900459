import React, { Component, Suspense } from 'react'
import { BrowserRouter, HashRouter, Route, Routes, redirect } from 'react-router-dom'
// import { Redirect } from 'react-router'
import './scss/style.scss'
import StandardErrorBoundary from './utils/errorBoundary';
import CacheBuster from 'react-cache-buster';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const AdminDash = React.lazy(() => import('./views/pages/adminDash/AdminDash'))
const ExpiredToken = React.lazy(() => import('./views/pages/expiredToken/ExpiredToken'))
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword/ResetPassword'))
const UpdatePasswordRequest = React.lazy(() => import('./views/pages/resetPassword/UpdatePassword'))
const Page401 = React.lazy(() => import('./views/pages/page401/Page401'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))


const isProduction = process.env.NODE_ENV === 'production';
const version = process.env.REACT_APP_VERSION
class App extends Component {
  
  render() {
    return (
      <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled. isProduction
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <StandardErrorBoundary>
        <BrowserRouter >
        <Suspense fallback={loading}>

          <Routes>
            
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/expired" name="Expired Page" element={<ExpiredToken />} />
            <Route exact path="/reset-password" name="ResetPassword" element={<ResetPassword />} />
            <Route exact path="/update-password" name="UpdatePasswordRequest" element={<UpdatePasswordRequest />} />
            <Route exact path="/401" name="Page 401" element={<Page401 />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            
            {/* TODO: verify email success and failure pages... */}
            <Route path="*" name="/" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
        </BrowserRouter>
      </StandardErrorBoundary>
        
      </CacheBuster>
      
    )
  }
}

export default App
